<template >
  <router-view />
</template>
<script>
export default {
  name: 'notificationManager',
}
</script>
<style lang="less">
  .html-outer-box {
    line-height: normal;
    box-sizing: content-box;
  }
</style>
